export const MerchandiseStylesMap =  {
  data() {
    return {
      defaultStyles: {
        colors: [
          {
            index: 0,
            value: '紅色'
          },
          {
            index: 1,
            value: '白色'
          },
          {
            index: 2,
            value: '灰色'
          },
          {
            index: 3,
            value: '藍色'
          },
          {
            index: 4,
            value: '黑色'
          },
          {
            index: 5,
            value: '黃色'
          },
          {
            index: 6,
            value: '紫色'
          },
          {
            index: 7,
            value: '粉紅色'
          },
          {
            index: 8,
            value: '綠色'
          },
          {
            index: 9,
            value: '深灰色'
          },
        ],
        sizes: [
          {
            index: 0,
            value: 'XS'
          },
          {
            index: 1,
            value: 'S'
          },
          {
            index: 2,
            value: 'M'
          },
          {
            index: 3,
            value: 'L'
          },
          {
            index: 4,
            value: 'XL'
          },
          {
            index: 5,
            value: '2XL'
          },
          {
            index: 6,
            value: '3XL'
          },
          {
            index: 7,
            value: '4XL'
          },
          {
            index: 8,
            value: '5XL'
          },
          {
            index: 9,
            value: '6XL'
          },
          {
            index: 10,
            value: '7XL'
          },
          {
            index: 11,
            value: '8XL'
          },
        ],
      }
    }
  },
}