<template>
  <div class="edit-merchandise pb-3" style="height: auto">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">編輯商品</p>
    </div>
    <!-- 內容 -->
    <div class="tw-container p-1">
      <div class="row">
        <!-- 編輯商品資訊 -->
        <div class="col-lg-5 col-12 mb-3 p-0 pe-1">
          <!-- 商品名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateName"
              placeholder="*商品名稱(100字)"
              maxlength="100"
              v-model="editMerchandiseData.name"
            />
            <label for="CreateName" class="text-danger">*商品名稱(100字)</label>
          </div>
          <hr />
          <!-- 上下架 -->
          <div class="form-check form-switch p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="CreateReady"
              v-model="editMerchandiseData.ready"
            />
            <label class="form-check-label" for="CreateReady">上架</label>
          </div>
          <p class="alert-danger fw-bolder mb-2 p-3" v-if="checkReadyError">
            您目前的收單時間已到期，故系統會自動幫您設定下架
          </p>
          <!-- 顧客自行結單 -->
          <div class="form-check form-switch p-0" v-if="storeInfo.canCheckOut">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="CreateCanCheckOut"
              v-model="editMerchandiseData.checkOut"
            />
            <label class="form-check-label" for="CreateCanCheckOut"
              >顧客可自行結單</label
            >
          </div>
          <hr />
          <!-- 商品分類 -->
          <div>
            <p class="tw-border-start fw-bolder mb-2">商品分類</p>
            <div
              style="height: 100px; overflow-y: scroll"
              v-if="!merchClassifications.loading"
            >
              <template v-if="merchClassifications.data.length > 0">
                <div class="row">
                  <div
                    class="form-check p-0 col-6"
                    v-for="data in merchClassifications.data"
                    :key="data.id"
                  >
                    <input
                      class="form-check-input m-0 me-2"
                      type="checkbox"
                      :value="data.id"
                      :id="`CreateCategory${data.id}`"
                      v-model="editMerchandiseData.merchClassificationIds"
                    />
                    <label
                      v-if="data.show"
                      class="form-check-label"
                      :for="`CreateCategory${data.id}`"
                    >
                      {{ data.name }}
                    </label>
                    <del v-else class="text-secondary" style="cursor: help;" title="此分類狀態，目前為「隱藏中」或「不在開放時間內」">{{ data.name }}</del>
                  </div>
                </div>
              </template>
              <p class="text-danger fw-bolder" v-else>
                您目前尚未有任何商品分類!
              </p>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <hr />
          <hr />
          <!-- 商品描述 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="商品描述"
              id="CreateDescription"
              style="height: 200px"
              maxlength="2000"
              v-model="editMerchandiseData.description"
            ></textarea>
            <label for="CreateDescription">商品描述(2000字)</label>
          </div>
          <hr />
          <!-- 商品備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="商品備註"
              id="CreateNote"
              style="height: 100px"
              maxlength="300"
              v-model="editMerchandiseData.note"
            ></textarea>
            <label for="CreateNote">商品備註(僅現賣家看到)(300字)</label>
          </div>
          <hr />
          <!-- 商品細節 -->
          <p class="tw-border-start fw-bolder mb-2">商品細節</p>
          <!-- VIP/販售狀態 -->
          <div class="row mb-3">
            <!-- VIP -->
            <div class="col-sm-6 p-0 pe-sm-1 mb-2 mb-sm-0">
              <template v-if="!storeVips.loading">
                <select
                  class="form-control"
                  name="merchandiseVip"
                  v-model="editMerchandiseData.storeVipId"
                >
                  <option value="">不設定 VIP</option>
                  <template
                    v-for="(vip, index) in storeVips.data"
                    :key="vip.id"
                  >
                    <option :value="vip.id" v-if="vip.id <= storeVip">
                      LV{{ index + 1 }}: {{ vip.name }}
                    </option>
                  </template>
                </select>
              </template>
              <AreaLoading v-else></AreaLoading>
            </div>
            <!-- 販售狀態 -->
            <div class="col-sm-6 p-0 ps-sm-1">
              <select
                class="form-control"
                name="merchandiseSellState"
                v-model="editMerchandiseData.sellState"
              >
                <option value="CANPREORDER">可預購</option>
                <option value="STOCKONLY">僅供現貨</option>
                <option value="LIMITED">限量</option>
              </select>
            </div>
          </div>
          <!-- 自定義編號 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateNumber"
              placeholder="自定義編號(20字)"
              maxlength="20"
              v-model="editMerchandiseData.number"
            />
            <label for="CreateNumber">自定義編號(20字)</label>
          </div>
          <!-- 自定義標籤 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateLabel"
              placeholder="自定義標籤(15字)"
              maxlength="15"
              v-model="editMerchandiseData.label"
            />
            <label for="CreateLabel">自定義標籤(15字)</label>
          </div>
          <!-- 倉儲位置 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateStorage"
              placeholder="倉儲位置(10字)"
              maxlength="10"
              v-model="editMerchandiseData.storageLocation"
            />
            <label for="CreateStorage">倉儲位置(10字)</label>
          </div>
          <!-- 成團數 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="CreateStartSaleQuantity"
              placeholder="成團數"
              min="0"
              v-model="editMerchandiseData.startSaleQuantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="CreateStartSaleQuantity">成團數</label>
          </div>
          <!-- 收單/到貨時間 -->
          <!-- 收單時間 -->
          <div class="mb-3">
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="CreateSwitchDeadLineTime"
                v-model="editMerchandiseData.checkDeadLineTime"
              />
              <label class="form-check-label" for="CreateSwitchDeadLineTime"
                >開啟收單時間</label
              >
            </div>
            <div
              class="form-floating"
              v-if="editMerchandiseData.checkDeadLineTime"
            >
              <input
                class="form-control"
                type="datetime-local"
                id="EditCheckDeadLineTime"
                placeholder="收單時間"
                v-model="editMerchandiseData.deadLineTime"
              />
              <label for="EditCheckDeadLineTime" class="mb-2">收單時間</label>
            </div>
          </div>
          <p class="alert-danger fw-bolder p-3" v-if="checkReadyError">
            您目前的收單時間已到期，故系統會自動幫您設定下架
          </p>
          <hr />
          <!-- 到貨時間 -->
          <div>
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="CreateSwitchArrivalTime"
                v-model="editMerchandiseData.checkArrivalTime"
              />
              <label class="form-check-label" for="CreateSwitchArrivalTime"
                >開啟到貨時間</label
              >
            </div>
            <div
              class="form-floating"
              v-if="editMerchandiseData.checkArrivalTime"
            >
              <input
                class="form-control"
                type="datetime-local"
                id="EditCheckArrivalTime"
                placeholder="到貨時間"
                v-model="editMerchandiseData.arrivalTime"
              />
              <label for="EditCheckArrivalTime" class="mb-2">到貨時間</label>
            </div>
          </div>
        </div>
        <!-- 編輯商品供應商/款式 -->
        <div class="col-lg-7 col-12 p-0 ps-1">
          <!-- 供應商 -->
          <div class="mb-3">
            <!-- title + btn -->
            <div class="mb-3">
              <span class="tw-border-start fw-bolder me-3">供應商</span>
              <button
                class="tw-btn tw-btn-success me-2"
                @click="showModal('createSupplierLink')"
              >
                新增
              </button>
              <button
                class="tw-btn tw-btn-danger me-2"
                @click="showModal('delSupplierLinks')"
              >
                刪除
              </button>
              <button
                v-if="supplierLinks.data.length > 0"
                class="tw-btn tw-btn-success"
                @click="editMode('SupplierTable')"
              >
                編輯 Mode
              </button>
            </div>
            <!-- table -->
            <div id="SupplierTable" class="edit-mode-responsive">
              <!-- 編輯模式下要顯示的區塊 -->
              <div class="edit-area">
                <!-- 編輯模式下操作按鈕的區塊 -->
                <div class="d-flex mb-2" v-if="editModeStatus.SupplierTable">
                  <button
                    class="tw-btn tw-btn-success me-2"
                    @click="updateSupplier('updateCost')"
                  >
                    完成
                  </button>
                  <button
                    class="tw-btn tw-btn-danger"
                    @click="closeEditMode('SupplierTable', true)"
                  >
                    關閉
                  </button>
                </div>
                <!-- 供應商表格 -->
                <template v-if="!supplierLinks.loading">
                  <table
                    class="table table-hover"
                    v-if="supplierLinks.data.length > 0"
                  >
                    <thead>
                      <tr>
                        <th v-if="!editModeStatus.SupplierTable">勾選</th>
                        <th style="min-width: 150px">供應商</th>
                        <th style="min-width: 100px">成本</th>
                        <th style="min-width: 150px">狀態</th>
                      </tr>
                    </thead>
                    <tbody class="bg-light">
                      <tr v-for="data in supplierLinks.data" :key="data.id">
                        <td v-if="!editModeStatus.SupplierTable">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            :value="data"
                            v-model="selectSupplierLinks"
                            :id="`supplier${data.id}`"
                          />
                        </td>
                        <td class="text-break">
                          {{ data.supplier.name }}
                        </td>
                        <td>
                          <span v-if="!editModeStatus.SupplierTable">{{
                            data.cost
                          }}</span>
                          <input
                            onwheel="this.blur()"
                            type="number"
                            @change="
                              data.cost = $methods.numberToFixed(data.cost)
                            "
                            v-model="data.cost"
                            v-else
                          />
                        </td>
                        <td>
                          <p class="fw-bolder text-success" v-if="data.preset">
                            預設供應商
                          </p>
                          <button
                            class="tw-btn tw-btn-success"
                            @click="updateSupplier('updatePreset', data)"
                            v-else-if="
                              !data.preset && !editModeStatus.SupplierTable
                            "
                          >
                            設為預設供應商
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="fw-bolder text-danger" v-else>
                    您尚未關聯任何供應商!
                  </p>
                </template>
                <AreaLoading v-else></AreaLoading>
              </div>
            </div>
          </div>
          <hr />
          <!-- 款式細項使用說明 -->
          <ul
            class="alert alert-info fw-bolder"
            style="list-style-type: decimal; position: static"
          >
            <li class="mb-2 ms-3">
              若要編輯款式名稱或售價或成本，請點擊「編輯Mode」
            </li>
            <li class="mb-2 ms-3">
              若名稱呈現紅色字體，表示你已刪除此款式，但款式尚有庫存
            </li>
            <li class="mb-2 ms-3">
              若想批次新增款式/細項，請在對應的輸入框內輸入您要的款式/細項並在各款式/細項之間加上「，」
            </li>
          </ul>
          <!-- 款式 -->
          <div v-if="!merchandiseStyles.loading">
            <!-- 主要款式 -->
            <div class="mb-3">
              <!-- title + btn -->
              <p class="tw-border-start fw-bolder mb-3">主要款式</p>
              <div class="mb-3 row">
                <div class="col-12 p-0 mb-2">
                  <input
                    id="MainStyle"
                    type="text"
                    class="form-control d-inline-block w-50"
                    placeholder="主要款式"
                    v-model="mainStyle.name"
                    @keyup.space="addComma('MainStyle', 'mainStyle')"
                    @keyup.enter="updateStyle('create', 0)"
                  />
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control d-inline-block w-25"
                    placeholder="單價"
                    min="0"
                    v-model="mainStyle.price"
                    @input="
                      mainStyle.price = $methods.numberToFixed(mainStyle.price)
                    "
                    @keyup.enter="updateStyle('create', 0)"
                  />
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control d-inline-block w-25"
                    placeholder="成本"
                    min="0"
                    v-model="mainStyle.cost"
                    @input="
                      mainStyle.cost = $methods.numberToFixed(mainStyle.cost)
                    "
                    @keyup.enter="updateStyle('create', 0)"
                  />
                </div>
                <div class="col-12 p-0 d-flex">
                  <button
                    class="tw-btn tw-btn-secondary fw-bolder my-2 mx-1"
                    @click="addComma('MainStyle', 'mainStyle')"
                  >
                    <img
                      class="icon"
                      title="逗號"
                      src="@/assets/icon/comma.png"
                      alt="，"
                    />
                  </button>
                  <button
                    class="tw-btn tw-btn-success my-2 mx-1"
                    @click="updateStyle('create', 0)"
                  >
                    新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success my-2 mx-1"
                    @click="showModal('batchAddSystemD4Styles', 0)"
                  >
                    批次新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success my-2 mx-1"
                    @click="showModal('addDiscounts')"
                  >
                    新增優惠
                  </button>
                  <button
                    class="tw-btn tw-btn-danger my-2 mx-1"
                    @click="showModal('delStyles', 0)"
                  >
                    刪除
                  </button>
                  <button
                    class="tw-btn tw-btn-secondary my-2 mx-1"
                    @click="showModal('recoverStyles', 0)"
                  >
                    恢復
                  </button>
                  <button
                    class="tw-btn tw-btn-success my-2 mx-1"
                    @click="editMode('MainStylesTable')"
                  >
                    編輯Mode
                  </button>
                </div>
              </div>
              <!-- table -->
              <div id="MainStylesTable" class="edit-mode-responsive">
                <div class="edit-area">
                  <!-- 編輯模式下操作按鈕的區塊 -->
                  <div
                    class="d-flex mb-2"
                    v-if="editModeStatus.MainStylesTable"
                  >
                    <button
                      class="tw-btn tw-btn-success me-2"
                      @click="updateStyle('update', 0)"
                    >
                      完成
                    </button>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="closeEditMode('MainStylesTable', true)"
                    >
                      關閉
                    </button>
                  </div>
                  <!-- 主要款式表格 -->
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 40px">移動</th>
                        <th style="width: 40px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="selectAll.mainStyles"
                          />
                        </th>
                        <th style="min-width: 100px">主要款式</th>
                        <th>
                          <span class="d-inline-block me-2">單價</span>
                          <input
                            onwheel="this.blur()"
                            class="d-inline-block w-75"
                            type="number"
                            v-if="editModeStatus.MainStylesTable"
                            @input="batchUpdatePrice"
                          />
                        </th>
                        <th>
                          <span class="d-inline-block me-2">成本</span>
                          <input
                            onwheel="this.blur()"
                            class="d-inline-block w-75"
                            type="number"
                            v-if="editModeStatus.MainStylesTable"
                            @input="batchUpdateCost"
                          />
                        </th>
                        <th style="min-width: 280px">多件優惠</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="merchandiseStyles.mainStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                    >
                      <template #item="{ element }">
                        <tr>
                          <!-- 拖曳 -->
                          <td
                            :class="{ handle: !editModeStatus.MainStylesTable }"
                            class="text-center pointer"
                            style="line-height: 35px"
                          >
                            <i
                              class="bi bi-arrows-move"
                              v-if="!editModeStatus.MainStylesTable"
                            ></i>
                          </td>
                          <!-- 多選 -->
                          <td>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              v-model="selectMainStyles"
                              :value="element"
                              v-if="!editModeStatus.MainStylesTable"
                            />
                          </td>
                          <!-- 主要款式 -->
                          <td
                            class="text-break"
                            :class="{ 'text-danger': element.deleted }"
                          >
                            <span v-if="!editModeStatus.MainStylesTable">{{
                              element.name
                            }}</span>
                            <input
                              type="text"
                              v-model="element.name"
                              maxlength="20"
                              v-else
                            />
                          </td>
                          <!-- 售價 -->
                          <td>
                            <span v-if="!editModeStatus.MainStylesTable">{{
                              element.price
                            }}</span>
                            <input
                              onwheel="this.blur()"
                              type="number"
                              min="0"
                              @input="
                                element.price = $methods.numberToFixed(
                                  element.price
                                )
                              "
                              v-model="element.price"
                              v-else
                            />
                          </td>
                          <!-- 成本 -->
                          <td>
                            <span v-if="!editModeStatus.MainStylesTable">{{
                              element.cost
                            }}</span>
                            <input
                              onwheel="this.blur()"
                              type="number"
                              min="0"
                              @input="
                                element.cost = $methods.numberToFixed(
                                  element.cost
                                )
                              "
                              v-model="element.cost"
                              v-else
                            />
                          </td>
                          <!-- 多件優惠 -->
                          <td class="text-start">
                            <ul
                              style="list-style-type: square"
                              v-if="!editModeStatus.MainStylesTable"
                            >
                              <li
                                v-for="discount in element.merchandiseDiscounts"
                                :key="discount.id"
                              >
                                <span
                                  v-if="
                                    discount.minQuantity && discount.maxQuantity
                                  "
                                >
                                  {{ discount.minQuantity }}~{{
                                    discount.maxQuantity
                                  }}個
                                </span>
                                <span
                                  v-else-if="
                                    discount.minQuantity &&
                                    !discount.maxQuantity
                                  "
                                >
                                  {{ discount.minQuantity }}個以上
                                </span>
                                ，單價:{{ discount.price }}
                                <span
                                  class="text-danger fw-bolder"
                                  v-if="discount.storeVipId"
                                  >(VIP{{ discount.storeVip.name }} ↑)</span
                                >
                                <img
                                  class="icon ms-2 d-inline"
                                  src="@/assets/icon/trash-2.png"
                                  alt="垃圾桶"
                                  @click="delDiscount(discount)"
                                />
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
            </div>
            <!-- 細項一 -->
            <div class="mb-3">
              <!-- title + btn -->
              <p class="tw-border-start fw-bolder mb-3">細項一</p>
              <div class="row">
                <div class="col-12 p-0 mb-2">
                  <input
                    id="OneStyle"
                    type="text"
                    class="form-control d-inline-block w-100"
                    placeholder="細項一"
                    v-model="oneStyleName"
                    @keyup.space="addComma('OneStyle', 'oneStyleName')"
                    @keyup.enter="updateStyle('create', 1)"
                  />
                </div>
                <div class="col-12 p-0">
                  <div class="text-end d-flex">
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder my-2 mx-1"
                      @click="addComma('OneStyle', 'oneStyleName')"
                    >
                      <img
                        class="icon"
                        title="逗號"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="updateStyle('create', 1)"
                    >
                      新增
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="showModal('batchAddSystemD4Styles', 1)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-danger my-2 mx-1"
                      @click="showModal('delStyles', 1)"
                    >
                      刪除
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary my-2 mx-1"
                      @click="showModal('recoverStyles', 1)"
                    >
                      恢復
                    </button>
                    <button
                      v-if="merchandiseStyles.oneStyles.length > 0"
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="editMode('OneStylesTable')"
                    >
                      編輯Mode
                    </button>
                  </div>
                </div>
              </div>
              <!-- table -->
              <div id="OneStylesTable" class="edit-mode-responsive">
                <div class="edit-area">
                  <!-- 編輯模式下操作按鈕的區塊 -->
                  <div class="d-flex mb-2" v-if="editModeStatus.OneStylesTable">
                    <button
                      class="tw-btn tw-btn-success me-2"
                      @click="updateStyle('update', 1)"
                    >
                      完成
                    </button>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="closeEditMode('OneStylesTable', true)"
                    >
                      關閉
                    </button>
                  </div>
                  <!-- 細項一表格 -->
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 40px">移動</th>
                        <th style="width: 40px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="selectAll.oneStyles"
                          />
                        </th>
                        <th style="min-width: 100px">細項一</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="merchandiseStyles.oneStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            :class="{ handle: !editModeStatus.OneStylesTable }"
                            class="text-center pointer"
                            style="line-height: 35px"
                          >
                            <i
                              class="bi bi-arrows-move"
                              v-if="!editModeStatus.OneStylesTable"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              v-model="selectOneStyles"
                              :value="element"
                              v-if="!editModeStatus.OneStylesTable"
                            />
                          </td>
                          <td>
                            <span
                              :class="{ 'text-danger': element.deleted }"
                              v-if="!editModeStatus.OneStylesTable"
                            >
                              {{ element.name }}
                            </span>
                            <input
                              type="text"
                              maxlength="20"
                              v-model="element.name"
                              v-else
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
            </div>
            <!-- 細項二 -->
            <div class="mb-3" v-if="merchandiseStyles.oneStyles.length > 0">
              <!-- title + btn -->
              <p class="tw-border-start fw-bolder mb-3">細項二</p>
              <div class="row">
                <div class="col-12 p-0 mb-2">
                  <input
                    id="TwoStyle"
                    type="text"
                    class="form-control d-inline-block w-100"
                    placeholder="細項二"
                    v-model="twoStyleName"
                    @keyup.space="addComma('TwoStyle', 'twoStyleName')"
                    @keyup.enter="updateStyle('create', 2)"
                  />
                </div>
                <div class="col-12 p-0">
                  <div class="text-end d-flex">
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder my-2 mx-1"
                      @click="addComma('TwoStyle', 'twoStyleName')"
                    >
                      <img
                        class="icon"
                        title="逗號"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="updateStyle('create', 2)"
                    >
                      新增
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="showModal('batchAddSystemD4Styles', 2)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-danger my-2 mx-1"
                      @click="showModal('delStyles', 2)"
                    >
                      刪除
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary my-2 mx-1"
                      @click="showModal('recoverStyles', 2)"
                    >
                      恢復
                    </button>
                    <button
                      v-if="merchandiseStyles.twoStyles.length > 0"
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="editMode('TwoStylesTable')"
                    >
                      編輯Mode
                    </button>
                  </div>
                </div>
              </div>
              <!-- table -->
              <div id="TwoStylesTable" class="edit-mode-responsive">
                <div class="edit-area">
                  <!-- 編輯模式下操作按鈕的區塊 -->
                  <div class="d-flex mb-2" v-if="editModeStatus.TwoStylesTable">
                    <button
                      class="tw-btn tw-btn-success me-2"
                      @click="updateStyle('update', 2)"
                    >
                      完成
                    </button>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="closeEditMode('TwoStylesTable', true)"
                    >
                      關閉
                    </button>
                  </div>
                  <!-- 細項二表格 -->
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 40px">移動</th>
                        <th style="width: 40px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="selectAll.twoStyles"
                          />
                        </th>
                        <th style="min-width: 100px">細項二</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="merchandiseStyles.twoStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            :class="{ handle: !editModeStatus.TwoStylesTable }"
                            class="text-center pointer"
                            style="line-height: 35px"
                          >
                            <i
                              class="bi bi-arrows-move"
                              v-if="!editModeStatus.TwoStylesTable"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              v-model="selectTwoStyles"
                              :value="element"
                              v-if="!editModeStatus.TwoStylesTable"
                            />
                          </td>
                          <td>
                            <span
                              :class="{ 'text-danger': element.deleted }"
                              v-if="!editModeStatus.TwoStylesTable"
                              >{{ element.name }}</span
                            >
                            <input
                              type="text"
                              maxlength="20"
                              v-model="element.name"
                              v-else
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
            </div>
            <!-- 細項三 -->
            <div class="mb-3" v-if="merchandiseStyles.twoStyles.length > 0">
              <!-- title + btn -->
              <p class="tw-border-start fw-bolder mb-3">細項三</p>
              <div class="row">
                <div class="col-12 p-0 mb-2">
                  <input
                    id="ThreeStyle"
                    type="text"
                    class="form-control d-inline-block w-100"
                    placeholder="細項三"
                    v-model="threeStyleName"
                    @keyup.space="addComma('ThreeStyle', 'threeStyleName')"
                    @keyup.enter="updateStyle('create', 3)"
                  />
                </div>
                <div class="col-12 p-0">
                  <div class="text-end d-flex">
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder my-2 mx-1"
                      @click="addComma('ThreeStyle', 'threeStyleName')"
                    >
                      <img
                        class="icon"
                        title="逗號"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="updateStyle('create', 3)"
                    >
                      新增
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="showModal('batchAddSystemD4Styles', 3)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-danger my-2 mx-1"
                      @click="showModal('delStyles', 3)"
                    >
                      刪除
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary my-2 mx-1"
                      @click="showModal('recoverStyles', 3)"
                    >
                      恢復
                    </button>
                    <button
                      v-if="merchandiseStyles.threeStyles.length > 0"
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="editMode('ThreeStylesTable')"
                    >
                      編輯Mode
                    </button>
                  </div>
                </div>
              </div>
              <!-- table -->
              <div id="ThreeStylesTable" class="edit-mode-responsive">
                <div class="edit-area">
                  <!-- 編輯模式下操作按鈕的區塊 -->
                  <div
                    class="d-flex mb-2"
                    v-if="editModeStatus.ThreeStylesTable"
                  >
                    <button
                      class="tw-btn tw-btn-success me-2"
                      @click="updateStyle('update', 3)"
                    >
                      完成
                    </button>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="closeEditMode('ThreeStylesTable', true)"
                    >
                      關閉
                    </button>
                  </div>
                  <!-- 細項三表格 -->
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 40px">移動</th>
                        <th style="width: 40px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="selectAll.threeStyles"
                          />
                        </th>
                        <th style="min-width: 100px">細項三</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="merchandiseStyles.threeStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            :class="{
                              handle: !editModeStatus.ThreeStylesTable,
                            }"
                            class="text-center pointer"
                            style="line-height: 35px"
                          >
                            <i
                              class="bi bi-arrows-move"
                              v-if="!editModeStatus.ThreeStylesTable"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              v-model="selectThreeStyles"
                              :value="element"
                              v-if="!editModeStatus.ThreeStylesTable"
                            />
                          </td>
                          <td>
                            <span
                              :class="{ 'text-danger': element.deleted }"
                              v-if="!editModeStatus.ThreeStylesTable"
                              >{{ element.name }}</span
                            >
                            <input
                              type="text"
                              maxlength="20"
                              v-model="element.name"
                              v-else
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
            </div>
            <!-- 細項四 -->
            <div class="mb-3" v-if="merchandiseStyles.threeStyles.length > 0">
              <!-- title + btn -->
              <p class="tw-border-start fw-bolder mb-3">細項四</p>
              <div class="row">
                <div class="col-12 p-0 mb-2">
                  <input
                    id="FourStyle"
                    type="text"
                    class="form-control d-inline-block w-100"
                    placeholder="細項四"
                    v-model="fourStyleName"
                    @keyup.space="addComma('FourStyle', 'fourStyleName')"
                    @keyup.enter="updateStyle('create', 4)"
                  />
                </div>
                <div class="col-12 p-0">
                  <div class="text-end d-flex">
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder my-2 mx-1"
                      @click="addComma('FourStyle', 'fourStyleName')"
                    >
                      <img
                        class="icon"
                        title="逗號"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="updateStyle('create', 4)"
                    >
                      新增
                    </button>
                    <button
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="showModal('batchAddSystemD4Styles', 4)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-danger my-2 mx-1"
                      @click="showModal('delStyles', 4)"
                    >
                      刪除
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary my-2 mx-1"
                      @click="showModal('recoverStyles', 4)"
                    >
                      恢復
                    </button>
                    <button
                      v-if="merchandiseStyles.fourStyles.length > 0"
                      class="tw-btn tw-btn-success my-2 mx-1"
                      @click="editMode('FourStylesTable')"
                    >
                      編輯Mode
                    </button>
                  </div>
                </div>
              </div>
              <!-- table -->
              <div id="FourStylesTable" class="edit-mode-responsive">
                <div class="edit-area">
                  <!-- 編輯模式下操作按鈕的區塊 -->
                  <div
                    class="d-flex mb-2"
                    v-if="editModeStatus.FourStylesTable"
                  >
                    <button
                      class="tw-btn tw-btn-success me-2"
                      @click="updateStyle('update', 4)"
                    >
                      完成
                    </button>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="closeEditMode('FourStylesTable', true)"
                    >
                      關閉
                    </button>
                  </div>
                  <!-- 細項四表格 -->
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 40px">移動</th>
                        <th style="width: 40px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="selectAll.fourStyles"
                          />
                        </th>
                        <th style="min-width: 100px">細項四</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="merchandiseStyles.fourStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            :class="{ handle: !editModeStatus.FourStylesTable }"
                            class="text-center pointer"
                            style="line-height: 35px"
                          >
                            <i
                              class="bi bi-arrows-move"
                              v-if="!editModeStatus.FourStylesTable"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              v-model="selectFourStyles"
                              :value="element"
                              v-if="!editModeStatus.FourStylesTable"
                            />
                          </td>
                          <td>
                            <span
                              :class="{ 'text-danger': element.deleted }"
                              v-if="!editModeStatus.FourStylesTable"
                              >{{ element.name }}</span
                            >
                            <input
                              type="text"
                              maxlength="20"
                              v-model="element.name"
                              v-else
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <AreaLoading v-else></AreaLoading>
        </div>
      </div>
      <hr />
      <div class="text-end">
        <router-link
          :to="`/seller/store/${storeId}/merchandise`"
          class="tw-btn tw-btn-secondary me-3"
          >返回</router-link
        >
        <button class="tw-btn tw-btn-success me-3" @click="updateMerchandise">
          完成
        </button>
      </div>
    </div>
  </div>
  <!-- 編輯模式下不顯示的區塊 -->
  <div class="edit-mode-bg d-none"></div>
  <!-- 新增供應商關聯的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="createSupplierLinkModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">新增供應商關聯</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 選擇供應商 -->
          <select
            class="form-control mb-3"
            v-model="createSupplierLinksData.selectSupplier"
          >
            <option :value="data" v-for="data in suppliers.data" :key="data.id">
              {{ data.name }}
            </option>
          </select>
          <!-- 成本 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="SupplierD4Cost"
              placeholder="成本"
              min="0"
              v-model="createSupplierLinksData.cost"
              @input="
                createSupplierLinksData.cost = $methods.numberToFixed(
                  createSupplierLinksData.cost
                )
              "
            />
            <label for="SupplierD4Cost">成本</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createSupplierLink"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除供應商關聯的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delSupplierLinksModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除供應商關聯
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>確定要刪除這些供應商關聯 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="delSupplierLinks"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯供應商成本的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateSupplierCostModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯成本</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="SupplierCost"
              placeholder="成本"
              min="0"
              v-model="supplierCost"
              @input="supplierCost = $methods.numberToFixed(supplierCost)"
            />
            <label for="SupplierCost">成本</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateSupplier('updateCost')"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯主要款式的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editMainStyleModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯主要款式</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearData"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditMainStyleName"
              placeholder="名稱(20字)"
              maxlength="20"
              v-model="mainStyle.name"
            />
            <label for="EditMainStyleName">名稱(20字)</label>
          </div>
          <!-- 單價 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="EditMainStylePrice"
              placeholder="單價"
              min="0"
              v-model="mainStyle.price"
              @input="mainStyle.price = $methods.numberToFixed(mainStyle.price)"
            />
            <label for="EditMainStylePrice">單價</label>
          </div>
          <!-- 成本 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="EditMainStyleCost"
              placeholder="成本"
              min="0"
              v-model="mainStyle.cost"
              @input="mainStyle.cost = $methods.numberToFixed(mainStyle.cost)"
            />
            <label for="EditMainStyleCost">成本</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="clearData"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStyle('update', 0)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯細項的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editDetailModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            編輯細項
            <span v-if="currentEditStyleStatus === 1">一</span>
            <span v-else-if="currentEditStyleStatus === 2">二</span>
            <span v-else-if="currentEditStyleStatus === 3">三</span>
            <span v-else-if="currentEditStyleStatus === 4">四</span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 細項一 -->
          <div class="form-floating mb-3" v-if="currentEditStyleStatus === 1">
            <input
              type="text"
              class="form-control"
              id="EditDetailOne"
              placeholder="細項一"
              maxlength="20"
              v-model="detailName"
            />
            <label for="EditDetailOne">細項一</label>
          </div>
          <!-- 細項二 -->
          <div
            class="form-floating mb-3"
            v-else-if="currentEditStyleStatus === 2"
          >
            <input
              type="text"
              class="form-control"
              id="EditDetailTwo"
              placeholder="細項二"
              maxlength="20"
              v-model="detailName"
            />
            <label for="EditDetailTwo">細項二</label>
          </div>
          <!-- 細項三 -->
          <div
            class="form-floating mb-3"
            v-else-if="currentEditStyleStatus === 3"
          >
            <input
              type="text"
              class="form-control"
              id="EditDetailThree"
              placeholder="細項三"
              maxlength="20"
              v-model="detailName"
            />
            <label for="EditDetailThree">細項三</label>
          </div>
          <!-- 細項四 -->
          <div
            class="form-floating mb-3"
            v-else-if="currentEditStyleStatus === 4"
          >
            <input
              type="text"
              class="form-control"
              id="EditDetailFour"
              placeholder="細項四"
              maxlength="20"
              v-model="detailName"
            />
            <label for="EditDetailFour">細項四</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStyle('update', currentEditStyleStatus)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除款式的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delStylesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除款式
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>確定要刪除這些款式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStylesStatus(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 恢復款式的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="recoverStylesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">恢復款式</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>確定要恢復這些款式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStylesStatus(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增優惠的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="addDiscountsModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">新增優惠</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="getMerchandiseStyles"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-md-6 p-0">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>主要款式</th>
                      <th>單價</th>
                      <th>優惠價</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in discountsData.mainStyle" :key="data.id">
                      <td>{{ data.name }}</td>
                      <td>{{ data.price }}</td>
                      <td>{{ data.currentDiscount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <!-- 設定款式 VIP -->
              <div class="mb-3">
                <p class="tw-border-start mb-2 fw-bolder">VIP等級</p>
                <select
                  class="form-control"
                  name="merchandiseVip"
                  v-if="!storeVips.loading"
                  v-model="discountsData.storeVipId"
                >
                  <option value="">不設定 VIP</option>
                  <template
                    v-for="(vip, index) in storeVips.data"
                    :key="vip.id"
                  >
                    <option :value="vip.id" v-if="vip.id <= storeVip">
                      LV{{ index + 1 }}: {{ vip.name }}
                    </option>
                  </template>
                </select>
                <AreaLoading v-else></AreaLoading>
              </div>
              <!-- 購買數量 -->
              <div class="mb-3">
                <p class="tw-border-start mb-2 fw-bolder">購買數量</p>
                <div class="row">
                  <div class="col-6 p-0 pe-1">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        placeholder="最小購買數量"
                        min="0"
                        v-model="discountsData.minQuantity"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                      <label>最小購買數量</label>
                    </div>
                  </div>
                  <div class="col-6 p-0 ps-1">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        placeholder="最大購買數量"
                        min="0"
                        v-model="discountsData.maxQuantity"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                      <label>最大購買數量</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 價格 -->
          <div class="mb-3">
            <div class="mb-3">
              <p class="tw-border-start mb-2 fw-bolder">優惠價格</p>
              <!-- 選擇優惠方式 -->
              <div class="row mb-3">
                <!-- 自行輸入價格 -->
                <div class="col-lg-4 col-12">
                  <div class="form-check p-0 col">
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectDiscountType"
                      id="SetOwnPrice"
                      value="setOwnPrice"
                      v-model="discountsData.selectDiscountType"
                    />
                    <label class="form-check-label" for="SetOwnPrice">
                      自行輸入價格
                    </label>
                  </div>
                </div>
                <!-- 套用原先價格 -->
                <div class="col-lg-4 col-12">
                  <div class="form-check p-0 col">
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectDiscountType"
                      id="SetOriginPrice"
                      value="setOriginPrice"
                      v-model="discountsData.selectDiscountType"
                    />
                    <label class="form-check-label" for="SetOriginPrice">
                      套用原先價格
                    </label>
                  </div>
                </div>
                <!-- 減少 % 數 -->
                <div class="col-lg-4 col-12">
                  <div class="form-check p-0 col">
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectDiscountType"
                      id="ReducePercent"
                      value="reducePercent"
                      v-model="discountsData.selectDiscountType"
                    />
                    <label class="form-check-label" for="ReducePercent">
                      減少 % 數
                    </label>
                  </div>
                </div>
                <!-- 減少金額 -->
                <div class="col-lg-4 col-12">
                  <div class="form-check p-0 col">
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectDiscountType"
                      id="ReducePrice"
                      value="reducePrice"
                      v-model="discountsData.selectDiscountType"
                    />
                    <label class="form-check-label" for="ReducePrice">
                      減少金額
                    </label>
                  </div>
                </div>
                <!-- 減少 % 數與金額，結果取最"高" -->
                <div class="col-lg-4 col-12">
                  <div class="form-check p-0 col">
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectDiscountType"
                      id="BothGetHigher"
                      value="bothGetHigher"
                      v-model="discountsData.selectDiscountType"
                    />
                    <label class="form-check-label" for="BothGetHigher">
                      減少 % 數與金額，結果取最"高"
                    </label>
                  </div>
                </div>
                <!-- 減少 % 數與金額，結果取最"低" -->
                <div class="col-lg-4 col-12">
                  <div class="form-check p-0 col">
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectDiscountType"
                      id="BothGetLower"
                      value="bothGetLower"
                      v-model="discountsData.selectDiscountType"
                    />
                    <label class="form-check-label" for="BothGetLower">
                      減少 % 數與金額，結果取最"低"
                    </label>
                  </div>
                </div>
              </div>
              <!-- 對應欄位 -->
              <div>
                <!-- 自行輸入價格 -->
                <div
                  class="form-floating mb-3"
                  v-if="discountsData.selectDiscountType === 'setOwnPrice'"
                >
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    placeholder="自行輸入金額"
                    v-model="discountsData.setOwnPrice"
                    @input="listenSetOwnPrice"
                  />
                  <label>自行輸入金額</label>
                </div>
                <!-- 減少%數 -->
                <div
                  class="form-floating mb-3"
                  v-if="
                    discountsData.selectDiscountType === 'reducePercent' ||
                    discountsData.selectDiscountType === 'bothGetHigher' ||
                    discountsData.selectDiscountType === 'bothGetLower'
                  "
                >
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    placeholder="減少%數"
                    v-model="discountsData.percent"
                    @input="listenReducePercent"
                  />
                  <label>減少%數</label>
                </div>
                <!-- 減少金額 -->
                <div
                  class="form-floating mb-3"
                  v-if="
                    discountsData.selectDiscountType === 'reducePrice' ||
                    discountsData.selectDiscountType === 'bothGetHigher' ||
                    discountsData.selectDiscountType === 'bothGetLower'
                  "
                >
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    placeholder="減少金額"
                    v-model="discountsData.reducePrice"
                    @input="listenReducePrice"
                  />
                  <label>減少金額</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 最終計算結果呈現 -->
          <div>
            <p class="tw-border-start mb-2 fw-bolder">優惠價呈現</p>
            <div class="row">
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectFinal"
                    id="MathRound"
                    value="mathRound"
                    v-model="discountsData.selectFinal"
                  />
                  <label class="form-check-label" for="MathRound">
                    優惠價以四捨五入<span class="text-success">取整數</span>
                  </label>
                </div>
              </div>
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectFinal"
                    id="MathFixed"
                    value="mathFixed"
                    v-model="discountsData.selectFinal"
                  />
                  <label class="form-check-label" for="MathFixed">
                    優惠價以四捨五入取<span class="text-danger"
                      >小數後兩位</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="getMerchandiseStyles"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createDiscount('batchAdd')"
          >
            繼續新增
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createDiscount('singleAdd')"
          >
            新增
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 批次新增系統預設款式的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchAddSystemD4StylesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">批次新增款式</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist" id="selectStyleTab">
            <li class="nav-item">
              <a
                @click="setBatchStyleStatus('color')"
                ref="colorTab"
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#colorTab"
                role="tab"
                aria-selected="true"
                style="cursor: pointer"
              >
                顏色
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="setBatchStyleStatus('size')"
                ref="sizeTab"
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#sizeTab"
                role="tab"
                aria-selected="false"
                style="cursor: pointer"
              >
                衣服尺寸
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="setBatchStyleStatus('number')"
                ref="numberTab"
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#numberTab"
                role="tab"
                aria-selected="false"
                style="cursor: pointer"
              >
                依照數字設定
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="setBatchStyleStatus('commonStyles')"
                ref="commonStylesTab"
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#commonStylesTab"
                role="tab"
                aria-selected="false"
                style="cursor: pointer"
              >
                常用款式
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <!-- color -->
            <div class="tab-pane fade" id="colorTab">
              <div class="pt-3">
                <div class="checkbox-group ms-3">
                  <div
                    class="mb-2"
                    v-for="color in defaultStyles.colors"
                    :key="color.index"
                  >
                    <div class="fancy-checkbox">
                      <label
                        ><input
                          type="checkbox"
                          :value="color"
                          name="color"
                          v-model="D4SystemStyles.selectColors"
                        /><span>{{ color.value }}</span></label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- size -->
            <div class="tab-pane fade" id="sizeTab">
              <div class="pt-3">
                <div class="checkbox-group ms-3">
                  <div
                    class="mb-2"
                    v-for="size in defaultStyles.sizes"
                    :key="size.index"
                  >
                    <div class="fancy-checkbox">
                      <label
                        ><input
                          type="checkbox"
                          :value="size"
                          name="size"
                          v-model="D4SystemStyles.selectSizes"
                        /><span>{{ size.value }}</span></label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- number -->
            <div class="tab-pane fade" id="numberTab">
              <div class="pt-3">
                <!-- 上排 -->
                <div class="row">
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        id="firstNumber"
                        placeholder="僅限輸入數字"
                        v-model="batchCreateByNumber.startNumber"
                        @input="
                          batchCreateByNumber.startNumber =
                            $methods.numberToFixed(
                              batchCreateByNumber.startNumber
                            )
                        "
                      />
                      <label for="firstNumber">起始數字</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        id="endNumber"
                        placeholder="僅限輸入數字"
                        v-model="batchCreateByNumber.endNumber"
                        @input="
                          batchCreateByNumber.endNumber =
                            $methods.numberToFixed(
                              batchCreateByNumber.endNumber
                            )
                        "
                      />
                      <label for="endNumber">結束數字</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        id="betweenNumber"
                        placeholder="僅限輸入數字"
                        min="1"
                        v-model="batchCreateByNumber.betweenNumber"
                        @input="
                          batchCreateByNumber.betweenNumber =
                            $methods.numberToFixed(
                              batchCreateByNumber.betweenNumber
                            )
                        "
                      />
                      <label for="betweenNumber">數字間距</label>
                    </div>
                  </div>
                </div>
                <!-- 下排 -->
                <div class="row">
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="insertFirstText"
                        placeholder="插入字首"
                        v-model="batchCreateByNumber.insertFirstText"
                      />
                      <label for="insertFirstText">插入字首</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="insertEndText"
                        placeholder="插入字尾"
                        v-model="batchCreateByNumber.insertEndText"
                      />
                      <label for="insertEndText">插入字尾</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        id="paddingDigits"
                        placeholder="補齊位數"
                        min="0"
                        v-model="batchCreateByNumber.paddingDigits"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                      <label for="paddingDigits">補齊位數</label>
                    </div>
                  </div>
                </div>
                <!-- 顯示結果 -->
                <div class="mt-3">
                  <p class="fw-bold mb-3">預覽結果</p>
                  <span
                    v-for="(data, index) in batchCreateByNumberResult"
                    :key="index"
                  >
                    {{ data }}&ensp;
                  </span>
                </div>
              </div>
            </div>
            <!-- commonStyles -->
            <div class="tab-pane fade" id="commonStylesTab">
              <div class="pt-3">
                <div v-if="!p_loading">
                  <template v-if="commonStyles.length > 0">
                    <template v-for="data in commonStyles" :key="data.id">
                      <div class="form-check p-0" v-if="data.selected">
                        <input
                          class="form-check-input m-0 me-2"
                          type="radio"
                          name="selectCommonStyles"
                          :value="data.value"
                          :id="`commonStyle${data.id}`"
                          v-model="commonStyle"
                        />
                        <label
                          class="form-check-label"
                          :for="`commonStyle${data.id}`"
                        >
                          {{ data.value }}
                        </label>
                      </div>
                    </template>
                  </template>
                  <p class="text-danger fw-bolder" v-else>
                    您尚未有任何常用款式
                  </p>
                </div>
                <AreaLoading v-else></AreaLoading>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchCreateSystemD4Styles"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// draggable
import Draggable from "vuedraggable";
// mixin
import { MerchandiseStylesMap } from '../../../methods/mixins/getSystemDefaultStyles'

export default {
  components: {Draggable},
  data() {
    return {
      // modal
      updateSupplierCostModal: {},
      createSupplierLinkModal: {},
      delSupplierLinksModal: {},
      delSupplierLinksModal: {},
      editMainStyleModal: {},
      editDetailModal: {},
      delStylesModal: {},
      recoverStylesModal: {},
      addDiscountsModal: {},
      batchAddSystemD4StylesModal: {},
      // tab
      sizeTab: {},
      colorTab: {},
      numberTab: {},
      commonStylesTab: {},
      // data
      serverToken: '',
      storeId: 0,
      merchandiseId: 0,
      checkReadyError: false,
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'name',
        data: []
      },
      storeInfo: {},
      // store vip
      storeVips: {
        loading: true,
        data: []
      },
      // 商品分類
      merchClassifications: {
        loading: true,
        data: []
      },
      // 賣場供應商
      suppliers: {
        loading: false,
        data: []
      },
      // 供應商關聯
      supplierLinks: {
        loading: false,
        data: []
      },
      editMerchandiseData: {
        name: '',
        description: '',
        storeVipId: '',
        ready: true,
        sellState: 'CANPREORDER',
        number: '',
        label: '',
        startSaleQuantity: 0,
        checkDeadLineTime: false,
        deadLineTime: '',
        storageLocation: '',
        checkOut: true,
        note: '',
        checkArrivalTime: false,
        arrivalTime: '',
        merchClassificationIds: [],
      },
      // 原本預設分類關聯
      D4MerchClassifications: [],
      // 商品款式
      merchandiseStyles: {
        loading: false,
        mainStyles: [],
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: [],
      },
      selectAll: {
        mainStyles: false,
        oneStyles: false,
        twoStyles: false,
        threeStyles: false,
        fourStyles: false,
      },
      // 新增供應商關聯
      createSupplierLinksData: {
        selectSupplier: {},
        cost: 0
      },
      // 編輯供應商成本
      supplierCost: 0,
      // 選擇關聯供應商
      selectSupplierLinks: [],
      // 新增/編輯款式
      mainStyle: {
        name: '',
        price: '',
        cost: ''
      },
      detailName: '',
      currentEditStyleStatus: 1,
      oneStyleName: '',
      twoStyleName: '',
      threeStyleName: '',
      fourStyleName: '',
      selectMainStyles: [],
      selectOneStyles: [],
      selectTwoStyles: [],
      selectThreeStyles: [],
      selectFourStyles: [],
      // 批次新增商品優惠
      discountsData: {
        storeVipId: '',
        minQuantity: 1,
        maxQuantity: 0,
        selectDiscountType: 'setOwnPrice',
        setOwnPrice: 0,
        percent: 0,
        reducePrice: 0,
        selectFinal: 'mathRound',
        mainStyle: [],
      },
      // 批次新增系統預設
      batchStyleCurrentStatus: 'color',
      D4SystemStyles: {
        selectColors: [],
        selectSizes: [],
      },
      // 批次新增依照數字
      batchCreateByNumber: {
        startNumber: 1,
        endNumber: 10,
        betweenNumber: 1,
        insertFirstText: "",
        insertEndText: "",
        paddingDigits: 0,
      },
      batchCreateByNumberResult: [],
      // 常用款式
      commonStyle: '',
      // 編輯模式
      editModeStatus: {
        SupplierTable: false,
        MainStylesTable: false,
        OneStylesTable: false,
        TwoStylesTable: false,
        ThreeStylesTable: false,
        FourStylesTable: false
      },
      recordSupplierLinks: [],
      recordMainStyles: [],
      recordOneStyles: [],
      recordTwoStyles: [],
      recordThreeStyles: [],
      recordFourStyles: [],
    }
  },
  mixins: [MerchandiseStylesMap],
  created() {
    this.initialization()
    this.getPrefer()
    this.getMerchandiseInfo()
    this.getStoreInfo()
    this.getSuppliers()
    this.getMerchandiseStyles()
  },
  mounted() {
    this.createModals(['createSupplierLinkModal', 'delSupplierLinksModal', 'updateSupplierCostModal',
      'delSupplierLinksModal', 'editMainStyleModal', 'editDetailModal', 'delStylesModal',
      'recoverStylesModal', 'addDiscountsModal', 'batchAddSystemD4StylesModal'
    ])
    this.createTabs(['sizeTab', 'colorTab', 'numberTab', 'commonStylesTab'])
  },
  watch: {
    // 監聽計算型態
    'discountsData.selectDiscountType'(val) {
      this.discountsData.setOwnPrice = 0
      this.discountsData.percent = 0
      this.discountsData.reducePrice = 0
      this.discountsData.mainStyle.forEach(item => {
        if (val === 'setOwnPrice') {
          if (this.discountsData.selectFinal === 'mathRound') item.currentDiscount = 0
          else item.currentDiscount = this.$methods.numberToFixed(0)
        } else {
          if (this.discountsData.selectFinal === 'mathRound') item.currentDiscount = Math.round(item.price)
          else item.currentDiscount = this.$methods.numberToFixed(item.price)
        }
      })
    },
    // 監聽最中價格呈現模式
    'discountsData.selectFinal'(val) {
      switch (this.discountsData.selectDiscountType) {
        case 'setOwnPrice':
          this.listenSetOwnPrice()
          break;
        case 'setOriginPrice':
          this.discountsData.mainStyle.forEach(item => {
            if (val === 'mathRound') item.currentDiscount = Math.round(item.price)
            else item.currentDiscount = this.$methods.numberToFixed(item.price)
          })
          break;
        case 'reducePercent':
          this.listenReducePercent()
          break;
        case 'reducePrice':
          this.listenReducePrice()
          break;
        case 'bothGetHigher':
          this.listenReducePercent()
          break;
        case 'bothGetLower':
          this.listenReducePercent()
          break;
      }
    },
    // 監聽主要款式陣列 > 做排序
    'merchandiseStyles.mainStyles'(val) {
      const saveSequence = val.some((item, index) => {
        return item.sequence !== index
      })
      if (saveSequence) this.saveSequence(0)
    },
    // 監聽細項一陣列 > 做排序
    'merchandiseStyles.oneStyles'(val) {
      const saveSequence = val.some((item, index) => {
        return item.sequence !== index
      })
      if (saveSequence) this.saveSequence(1)
    },
    // 監聽細項二陣列 > 做排序
    'merchandiseStyles.twoStyles'(val) {
      const saveSequence = val.some((item, index) => {
        return item.sequence !== index
      })
      if (saveSequence) this.saveSequence(2)
    },
    // 監聽細項三陣列 > 做排序
    'merchandiseStyles.threeStyles'(val) {
      const saveSequence = val.some((item, index) => {
        return item.sequence !== index
      })
      if (saveSequence) this.saveSequence(3)
    },
    // 監聽細項四陣列 > 做排序
    'merchandiseStyles.fourStyles'(val) {
      const saveSequence = val.some((item, index) => {
        return item.sequence !== index
      })
      if (saveSequence) this.saveSequence(4)
    },
    // 監聽批次新增 > 依照數字
    batchCreateByNumber: {
      handler(val) {
        this.batchCreateByNumberResult = [];
        let array = [];
        let startNumber = val.startNumber;
        let endNumber = val.endNumber;
        let betweenNumber = val.betweenNumber ? val.betweenNumber : 1;
        let insertZero = "00000";
        // 先將起始數字加入陣列
        array.push(startNumber);
        // 再來加入數字間距將後續數字加入陣列內
        let index = 0
        while (startNumber < endNumber) {
          index ++
          startNumber += betweenNumber;
          array.push(this.$methods.numberToFixed(startNumber));
          if (index > 30) break
        }
        console.log(array);
        array.forEach((item) => {
          // 判斷陣列內數字有沒有大於結束數字
          if (item <= endNumber) {
            let currentData = item.toString();
            // 補齊位數
            if (currentData.length < val.paddingDigits) {
              currentData = (
                currentData.slice(0, 1) +
                insertZero.slice(0, val.paddingDigits - currentData.length) +
                currentData
              ).slice("1");
            }
            // 插入字首
            currentData = (
              currentData.slice(0, 1) +
              val.insertFirstText +
              currentData
            ).slice(1);
            // 插入字尾
            currentData += val.insertEndText;
            this.batchCreateByNumberResult.push(currentData);
          }
        });
        // 判斷陣列長度，最大 20 筆
        if (this.batchCreateByNumberResult.length > 20) {
          this.batchCreateByNumberResult = [];
          this.batchCreateByNumberResult.push("筆數不得超過 20 筆!");
        }
      },
      deep: true,
    },
    // 監聽上下架與收單時間的關係
    'editMerchandiseData.ready'() {
      this.checkReady()
    },
    'editMerchandiseData.checkDeadLineTime'() {
      this.checkReady()
    },
    'editMerchandiseData.deadLineTime'() {
      this.checkReady()
    },
    // 監聽全選款式
    'selectAll.mainStyles'(val) {
      this.selectMainStyles = []
      if (val) {
        this.merchandiseStyles.mainStyles.forEach(item => {
          this.selectMainStyles.push(item)
        })
      }
    },
    'selectAll.oneStyles'(val) {
      this.selectOneStyles = []
      if (val) {
        this.merchandiseStyles.oneStyles.forEach(item => {
          this.selectOneStyles.push(item)
        })
      }
    },
    'selectAll.twoStyles'(val) {
      this.selectTwoStyles = []
      if (val) {
        this.merchandiseStyles.twoStyles.forEach(item => {
          this.selectTwoStyles.push(item)
        })
      }
    },
    'selectAll.threeStyles'(val) {
      this.selectThreeStyles = []
      if (val) {
        this.merchandiseStyles.threeStyles.forEach(item => {
          this.selectThreeStyles.push(item)
        })
      }
    },
    'selectAll.fourStyles'(val) {
      this.selectFourStyles = []
      if (val) {
        this.merchandiseStyles.fourStyles.forEach(item => {
          this.selectFourStyles.push(item)
        })
      }
    },
  },
  computed: {
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 常用款式
    commonStyles() {
      return this.$store.state.Prefer.p_commonStyles.commonStyles
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.storeId = this.$route.params.storeId
      this.merchandiseId = this.$route.query.id
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    showModal(status, item) {
      if (status === 'createSupplierLink') {
        // 新增供應商關聯
        if (this.suppliers.loading) return this.SweetAlert('other', '請稍後~正在為您準備賣場供應商')
        if (this.suppliers.data.length === 0) return this.SweetAlert('other', '您尚未在賣場新增任何供應商')
        this.createSupplierLinksData = {
          selectSupplier: this.suppliers.data[0],
          cost: 0
        }
        this.createSupplierLinkModal.show()
      } else if (status === 'delSupplierLinks') {
        // 刪除供應商關聯
        if (this.selectSupplierLinks.length === 0) return this.SweetAlert('other', '請至少選擇一筆供應商關聯')
        this.propsSelectInfos.data = this.selectSupplierLinks
        this.delSupplierLinksModal.show()
      } else if (status === 'updateSupplierLinkCost') {
        // 編輯供應商成本
        this.supplierCost = item.cost
        this.selectSupplierLinks = []
        this.propsSelectInfos.data = []
        this.selectSupplierLinks.push(item)
        this.propsSelectInfos.data = this.selectSupplierLinks
        this.updateSupplierCostModal.show()
      } else if (status === 'editMainStyle') {
        // 編輯主要款式
        this.selectMainStyles = []
        this.selectMainStyles.push(item)
        this.mainStyle = {
          name: item.name,
          price: item.price,
          cost: item.cost
        }
        this.editMainStyleModal.show()
      } else if (status === 'editDetail') {
        // 編輯細項
        this.detailName = ''
        this.detailName = item.name
        this.currentEditStyleStatus = item.hierarchy
        switch (this.currentEditStyleStatus) {
          case 1:
            this.selectOneStyles = []
            this.selectOneStyles.push(item)
            break
          case 2:
            this.selectTwoStyles = []
            this.selectTwoStyles.push(item)
            break
          case 3:
            this.selectThreeStyles = []
            this.selectThreeStyles.push(item)
            break
          case 4:
            this.selectFourStyles = []
            this.selectFourStyles.push(item)
            break
        }
        this.editDetailModal.show()
      } else if (status === 'delStyles') {
        // 刪除款式
        this.propsSelectInfos.data = []
        switch (item) {
          case 0:
            if (this.selectMainStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆主要款式')
            this.propsSelectInfos.data = this.selectMainStyles
          break;
          case 1:
            if (this.selectOneStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆細項一')
            this.propsSelectInfos.data = this.selectOneStyles
          break;
          case 2:
            if (this.selectTwoStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆細項二')
            this.propsSelectInfos.data = this.selectTwoStyles
          break;
          case 3:
            if (this.selectThreeStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆細項三')
            this.propsSelectInfos.data = this.selectThreeStyles
          break;
          case 4:
            if (this.selectFourStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆細項四')
            this.propsSelectInfos.data = this.selectFourStyles
          break;
        }
        this.delStylesModal.show()
      } else if (status === 'recoverStyles') {
        // 恢復款式
        this.propsSelectInfos.data = []
        switch (item) {
          case 0:
            if (this.selectMainStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆被刪除主要款式')
            this.propsSelectInfos.data = this.selectMainStyles.filter(item => {
              return item.deleted
            })
            this.selectMainStyles = this.propsSelectInfos.data
          break;
          case 1:
            if (this.selectOneStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆被刪除細項一')
            this.propsSelectInfos.data = this.selectOneStyles.filter(item => {
              return item.deleted
            })
            this.selectOneStyles = this.propsSelectInfos.data
          break;
          case 2:
            if (this.selectTwoStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆被刪除細項二')
            this.propsSelectInfos.data = this.selectTwoStyles.filter(item => {
              return item.deleted
            })
            this.selectTwoStyles = this.propsSelectInfos.data
          break;
          case 3:
            if (this.selectThreeStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆被刪除細項三')
            this.propsSelectInfos.data = this.selectThreeStyles.filter(item => {
              return item.deleted
            })
            this.selectThreeStyles = this.propsSelectInfos.data
          break;
          case 4:
            if (this.selectFourStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆被刪除細項四')
            this.propsSelectInfos.data = this.selectFourStyles.filter(item => {
              return item.deleted
            })
            this.selectFourStyles = this.propsSelectInfos.data
          break;
        }
        if (this.propsSelectInfos.data.length === 0) return this.SweetAlert('other', '您尚未選中被刪除的款式')
        this.recoverStylesModal.show()
      } else if (status === 'addDiscounts') {
        // 新增優惠
        if (this.selectMainStyles.length === 0) return this.SweetAlert('other', '請至少選擇一筆主要款式')
        this.discountsData = {
          storeVipId: '',
          minQuantity: 1,
          maxQuantity: 0,
          selectDiscountType: 'setOwnPrice',
          setOwnPrice: 0,
          percent: 0,
          reducePrice: 0,
          selectFinal: 'mathRound',
          mainStyle: [],
        }
        this.selectMainStyles.forEach(item => {
          this.discountsData.mainStyle.push({
            id: item.id,
            price: item.price,
            name: item.name,
            currentDiscount: 0
          })
        })
        this.addDiscountsModal.show()
      } else if (status === 'batchAddSystemD4Styles') {
        // 批次新增系統預設款式
        this.batchStyleCurrentStatus = 'color'
        this.colorTab.show()
        this.D4SystemStyles = {
          selectColors: [],
          selectSizes: [],
        }
        this.batchCreateByNumberResult = []
        this.batchCreateByNumber = {
          startNumber: 1,
          endNumber: 10,
          betweenNumber: 1,
          insertFirstText: "",
          insertEndText: "",
          paddingDigits: 0,
        }
        this.commonStyle = ''
        this.currentEditStyleStatus = item
        this.batchAddSystemD4StylesModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreVips,getMerchClassifications,getSuppliers}'
        }
      ]
      this.storeInfo = {}
      this.storeVips = {
        loading: true,
        data: []
      }
      this.merchClassifications = {
        loading: true,
        data: []
      }
      this.suppliers = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // storeVip
            vm.storeVip = storeInfo.storeVipId ? storeInfo.storeVipId : 0
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            // 商品分類
            vm.merchClassifications = {
              loading: false,
              data: vm.merchClassificationsFilter(storeInfo.merchClassifications)
            }
            // 供應商
            vm.suppliers = {
              loading: false,
              data: storeInfo.suppliers
            }
            if (storeInfo.suppliers.length > 0) vm.createSupplierLinksData.selectSupplier = storeInfo.suppliers[0]
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得商品資訊
    getMerchandiseInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchClassificationLinks{getMerchClassification}}'
        }
      ]
      this.editMerchandiseData = {
        name: '',
        description: '',
        storeVipId: '',
        ready: true,
        sellState: 'CANPREORDER',
        number: '',
        label: '',
        startSaleQuantity: 0,
        checkDeadLineTime: false,
        deadLineTime: '',
        storageLocation: '',
        checkOut: true,
        note: '',
        checkArrivalTime: false,
        arrivalTime: '',
        merchClassificationIds: [],
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            vm.editMerchandiseData = {
              name: merchandiseInfo.name,
              description: merchandiseInfo.description,
              storeVipId: merchandiseInfo.storeVipId ? merchandiseInfo.storeVipId : '',
              ready: merchandiseInfo.ready,
              sellState: merchandiseInfo.sellState,
              number: merchandiseInfo.number,
              label: merchandiseInfo.label,
              startSaleQuantity: merchandiseInfo.startSaleQuantity,
              checkDeadLineTime: merchandiseInfo.deadLineTime ? true : false,
              deadLineTime: merchandiseInfo.deadLineTime ? vm.$methods.moment(merchandiseInfo.deadLineTime).format('YYYY-MM-DDTHH:mm') : '',
              storageLocation: merchandiseInfo.storageLocation,
              checkOut: merchandiseInfo.checkOut,
              note: merchandiseInfo.note,
              checkArrivalTime: merchandiseInfo.arrivalTime ? true : false,
              arrivalTime: merchandiseInfo.arrivalTime ? vm.$methods.moment(merchandiseInfo.arrivalTime).format('YYYY-MM-DDTHH:mm') : '',
              merchClassificationIds: [],
            }
            merchandiseInfo.merchClassificationLinks.forEach(item => {
              let object = {
                id: item.merchClassificationId,
                linkId: item.id,
                name: item.merchClassification.name
              }
              vm.editMerchandiseData.merchClassificationIds.push(item.merchClassificationId)
              vm.D4MerchClassifications.push(JSON.parse(JSON.stringify(object)))
            });
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得關聯供應商
    getSuppliers(supplierChange) {
      const vm = this
      const getSupplierApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchandiseSupplierLinks{getSupplier}}'
        }
      ]
      this.selectSupplierLinks = []
      this.supplierLinks = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getSupplierApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            merchandiseInfo.merchandiseSupplierLinks.forEach(item => {
              item.name = item.supplier.name
              vm.supplierLinks.data.push(item)
            })
            // 如果供應商成本有異動或是更換預設供應商的話就更新款式成本
            if (supplierChange) vm.updateSylesCost()
            vm.supplierLinks.loading = false
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得商品款式
    getMerchandiseStyles() {
      const vm = this
      const getMerchandiseStylesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}}}'
        }
      ]
      this.selectMainStyles = []
      this.selectOneStyles = []
      this.selectTwoStyles = []
      this.selectThreeStyles = []
      this.selectFourStyles = []
      this.merchandiseStyles = {
        loading: true,
        mainStyles: [],
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: [],
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseStylesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            merchandiseInfo.merchandiseStyles.forEach(item => {
              if (item.hierarchy === 0) {
                vm.merchandiseStyles.mainStyles.push(item)
              } else if (item.hierarchy === 1) {
                vm.merchandiseStyles.oneStyles.push(item)
              } else if (item.hierarchy === 2) {
                vm.merchandiseStyles.twoStyles.push(item)
              } else if (item.hierarchy === 3) {
                vm.merchandiseStyles.threeStyles.push(item)
              } else if (item.hierarchy === 4) {
                vm.merchandiseStyles.fourStyles.push(item)
              }
            })
            vm.merchandiseStyles.loading = false
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 更新款式成本
    updateSylesCost() {
      const vm = this
      const updateMainStyleApi = `${process.env.VUE_APP_API}/merchandiseStyle/update`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      let cost = 0
      this.supplierLinks.data.forEach(item => {
        if (item.preset) cost = item.cost
      })
      this.merchandiseStyles.mainStyles.forEach(item => {
        data.push({
          id: item.id,
          name: item.name,
          price: item.price ? item.price : 0,
          cost: cost
        })
      })
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateMainStyleApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert('setByMyself', {
              icon: 'success',
              title: {
                show: true,
                value: '成功'
              },
              text: {
                show: true,
                value: '已將成本帶入所有主要款式內'
              },
              timer: 3000
            })
            vm.getMerchandiseStyles()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 完成
    updateMerchandise() {
      this.$methods.switchLoading('show')
      const vm = this
      const updateMerchandiseApi = `${process.env.VUE_APP_API}/merchandise/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.merchandiseId,
        storeId: this.storeId,
        name: this.editMerchandiseData.name,
        description: this.editMerchandiseData.description,
        storeVipId: this.editMerchandiseData.storeVipId,
        ready: this.editMerchandiseData.ready,
        sellState: this.editMerchandiseData.sellState,
        number: this.editMerchandiseData.number,
        label: this.editMerchandiseData.label,
        startSaleQuantity: this.editMerchandiseData.startSaleQuantity,
        deadLineTime: this.editMerchandiseData.checkDeadLineTime ? new Date(this.editMerchandiseData.deadLineTime) * 1 : null,
        storageLocation: this.editMerchandiseData.storageLocation,
        checkOut:this.editMerchandiseData.checkOut,
        note: this.editMerchandiseData.note,
        arrivalTime: this.editMerchandiseData.checkArrivalTime ? new Date(this.editMerchandiseData.arrivalTime) * 1 : null
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateMerchandiseApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.updatemerchClassifications()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 更新商品分類
    updatemerchClassifications() {
      const vm = this
      const updatemerchClassificationsApi = `${process.env.VUE_APP_API}/merchClassificationLink/updateMultipuleSelection`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      let merchClassificationsMap = new Map()
      // 原本就有選到的
      this.D4MerchClassifications.forEach(item => {
        merchClassificationsMap.set(item.id, [{
          id: item.id,
          linkId: item.linkId
        }])

      })
      // 後來選的
      this.editMerchandiseData.merchClassificationIds.forEach(item => {
        if (!merchClassificationsMap.has(item)) {
          merchClassificationsMap.set(item, [{
            id: item,
            linkId: 0
          }])
        } else {
          merchClassificationsMap.get(item).push({
            id: item,
            linkId: 0
          })
        }
      })
      console.log(merchClassificationsMap)
      for (const [key, value] of merchClassificationsMap) {
        // 如果列長度大於 1 的話，代表原先就有的分類但沒有要被刪除
        if (value.length === 1) {
          if (value[0].linkId === 0) {
            // 後來選的分類
            data.push({
              selected: true,
              merchandiseId: this.merchandiseId,
              merchClassificationId: key
            })
          } else {
            // 原先就有的分類，但是後來要刪除的
            data.push({
              selected: false,
              linkId: value[0].linkId
            })
          }
        }
      }
      console.log(data)
      if (data.length === 0) {
        this.$methods.switchLoading('hide')
        return this.$router.push(`/seller/store/${vm.storeId}/merchandise`)
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: updatemerchClassificationsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            vm.$router.push(`/seller/store/${vm.storeId}/merchandise`)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 新增供應商關聯
    createSupplierLink() {
      const vm = this
      const createSupplierLinkApi = `${process.env.VUE_APP_API}/merchandiseSupplierLink/create`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        merchandiseId: this.merchandiseId,
        supplierId: this.createSupplierLinksData.selectSupplier.id,
        cost: this.createSupplierLinksData.cost ? this.createSupplierLinksData.cost : 0
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: createSupplierLinkApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            if (vm.supplierLinks.data.length === 0) {
              const supplierLink = res.data[0]
              vm.updateSupplier('updatePreset', supplierLink)
            } else {
              vm.SweetAlert(res.code, res.message)
              vm.getSuppliers()
            }
            vm.createSupplierLinkModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除供應商關聯
    delSupplierLinks() {
      const vm = this
      const delSupplierLinksApi = `${process.env.VUE_APP_API}/merchandiseSupplierLink/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      let linkIds = []
      this.selectSupplierLinks.forEach(item => {
        linkIds.push(item.id)
      })
      data.append('linkIds', linkIds.toString())
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delSupplierLinksApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getSuppliers()
            vm.delSupplierLinksModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 更新供應商成本/預設供應商
    updateSupplier(status, item) {
      const vm = this
      const updateSupplierCostApi = `${process.env.VUE_APP_API}/merchandiseSupplierLink/update`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      if (status === 'updateCost') {
        // 編輯成本
        this.supplierLinks.data.forEach(supplier => {
          data.push({
            id: supplier.id,
            cost: supplier.cost,
            preset: supplier.preset
          })
        })
      } else {
        // 預設供應商
        data = [{
          id: item.id,
          cost: item.cost,
          preset: true
        }]
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateSupplierCostApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getSuppliers(true)
            vm.closeEditMode('SupplierTable', false)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 新增/編輯款式
    updateStyle(status, hierarchy) {
      const vm = this
      const updateMainStyleApi = `${process.env.VUE_APP_API}/merchandiseStyle/${status}`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      if (status === 'create') {
        // 新增款式
        switch (hierarchy) {
          case 0:
            if (!this.mainStyle.name) return this.SweetAlert('other', '主要款式名稱不能為空')
            // 先將輸入的文字去除空白
            const mainStyleArray = this.mainStyle.name.replace(/\s*/g, "").split("，");
            // 判斷批次新增的細項自數是否超過 20 字
            const error = mainStyleArray.some((item) => {
              return item.length > 20;
            });
            if (error) return this.SweetAlert('other', '主要款式的字數其中有超過20字')
            mainStyleArray.forEach(item => {
              data.push({
                merchandiseId: this.merchandiseId,
                hierarchy: hierarchy,
                name: item,
                price: this.mainStyle.price ? this.mainStyle.price : 0,
                cost: this.mainStyle.cost ? this.mainStyle.cost : 0
              })
            })
            break;
          case 1:
            if (!this.oneStyleName) return this.SweetAlert('other', '細項一名稱不能為空')
            // 先將輸入的文字去除空白
            const oneStyleArray = this.oneStyleName.replace(/\s*/g, "").split("，");
            // 判斷批次新增的細項自數是否超過 20 字
            const errorA = oneStyleArray.some((item) => {
              return item.length > 20;
            });
            if (errorA) return this.SweetAlert('other', '細項一的字數其中有超過20字')
            oneStyleArray.forEach(item => {
              data.push({
                merchandiseId: this.merchandiseId,
                hierarchy: hierarchy,
                name: item
              })
            })
            break;
          case 2:
            if (!this.twoStyleName) return this.SweetAlert('other', '細項二名稱不能為空')
            // 先將輸入的文字去除空白
            const twoStyleArray = this.twoStyleName.replace(/\s*/g, "").split("，");
            // 判斷批次新增的細項自數是否超過 20 字
            const errorB = twoStyleArray.some((item) => {
              return item.length > 20;
            });
            if (errorB) return this.SweetAlert('other', '細項二的字數其中有超過20字')
            twoStyleArray.forEach(item => {
              data.push({
                merchandiseId: this.merchandiseId,
                hierarchy: hierarchy,
                name: item
              })
            })
            break;
          case 3:
            if (!this.threeStyleName) return this.SweetAlert('other', '細項三名稱不能為空')
            // 先將輸入的文字去除空白
            const threeStyleArray = this.threeStyleName.replace(/\s*/g, "").split("，");
            // 判斷批次新增的細項自數是否超過 20 字
            const errorC = threeStyleArray.some((item) => {
              return item.length > 20;
            });
            if (errorC) return this.SweetAlert('other', '細項三的字數其中有超過20字')
            threeStyleArray.forEach(item => {
              data.push({
                merchandiseId: this.merchandiseId,
                hierarchy: hierarchy,
                name: item
              })
            })
            break;
          case 4:
            if (!this.fourStyleName) return this.SweetAlert('other', '細項四名稱不能為空')
            // 先將輸入的文字去除空白
            const fourStyleArray = this.fourStyleName.replace(/\s*/g, "").split("，");
            // 判斷批次新增的細項自數是否超過 20 字
            const errorD = fourStyleArray.some((item) => {
              return item.length > 20;
            });
            if (errorD) return this.SweetAlert('other', '細項四的字數其中有超過20字')
            fourStyleArray.forEach(item => {
              data.push({
                merchandiseId: this.merchandiseId,
                hierarchy: hierarchy,
                name: item
              })
            })
            break;
        }
      } else if (status === 'update') {
        // 編輯款式
        switch (hierarchy) {
          case 0:
            this.merchandiseStyles.mainStyles.forEach(item => {
              data.push({
                id: item.id,
                name: item.name,
                price: item.price ? item.price : 0,
                cost: item.cost ? item.cost : 0
              })
            })
            break;
          case 1:
            this.merchandiseStyles.oneStyles.forEach(item => {
              data.push({
                id: item.id,
                name: item.name,
              })
            })
            break;
          case 2:
            this.merchandiseStyles.twoStyles.forEach(item => {
              data.push({
                id: item.id,
                name: item.name,
              })
            })
            break;
          case 3:
            this.merchandiseStyles.threeStyles.forEach(item => {
              data.push({
                id: item.id,
                name: item.name,
              })
            })
            break;
          case 4:
            this.merchandiseStyles.fourStyles.forEach(item => {
              data.push({
                id: item.id,
                name: item.name,
              })
            })
            break;
        }
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: status === 'create' ? 'POST' : 'PUT',
        async: true,
        url: updateMainStyleApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.selectMainStyles = []
            vm.mainStyle = {
              name: '',
              price: '',
              cost: ''
            }
            vm.oneStyleName = ''
            vm.twoStyleName = ''
            vm.threeStyleName = ''
            vm.fourStyleName = ''
            vm.getMerchandiseStyles()
            if (status === 'update') {
              if (hierarchy === 0) vm.closeEditMode('MainStylesTable', false)
              else if (hierarchy === 1) vm.closeEditMode('OneStylesTable', false)
              else if (hierarchy === 2) vm.closeEditMode('TwoStylesTable', false)
              else if (hierarchy === 3) vm.closeEditMode('ThreeStylesTable', false)
              else if (hierarchy === 4) vm.closeEditMode('FourStylesTable', false)
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 批次更新款式單價
    batchUpdatePrice(element) {
      console.log(element)
      console.log(element.target.value)
      this.merchandiseStyles.mainStyles.forEach(style => {
        console.log(element.target.value)
        style.price = this.$methods.numberToFixed(element.target.value)
      })
    },
    // 批次更新款式成本
    batchUpdateCost(element) {
      console.log(element)
      console.log(element.target.value)
      this.merchandiseStyles.mainStyles.forEach(style => {
        console.log(element.target.value)
        style.cost = this.$methods.numberToFixed(element.target.value)
      })
    },
    // 清除資料
    clearData() {
      this.mainStyle = {
        name: '',
        price: '',
        cost: ''
      }
    },
    // 刪除/復原款式
    updateStylesStatus(deleteStatus) {
      const vm = this
      const updateStylesStatusApi = `${process.env.VUE_APP_API}/merchandiseStyle/setDeleted`
      const header = {
        authorization: this.serverToken
      }
      let styleIds = []
      this.propsSelectInfos.data.forEach(item => {
        styleIds.push(item.id)
      })
      const data = new FormData()
      data.append('styleIds', styleIds.toString())
      data.append('deleted', deleteStatus)
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateStylesStatusApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.selectMainStyles = []
            vm.selectOneStyles = []
            vm.selectTwoStyles = []
            vm.selectThreeStyles = []
            vm.selectFourStyles = []
            vm.delStylesModal.hide()
            vm.recoverStylesModal.hide()
            vm.getMerchandiseStyles()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 監聽優惠 > 自行輸入
    listenSetOwnPrice() {
      this.discountsData.setOwnPrice = this.$methods.numberToFixed(this.discountsData.setOwnPrice)
      this.discountsData.mainStyle.forEach(item => {
        const setOwnPrice = this.discountsData.setOwnPrice ? this.discountsData.setOwnPrice : 0
        if (this.discountsData.selectFinal === 'mathRound') item.currentDiscount = Math.round(setOwnPrice)
        else item.currentDiscount = this.$methods.numberToFixed(setOwnPrice)
      })
    },
    // 監聽優惠 > 減少 % 數
    listenReducePercent() {
      this.discountsData.percent = this.$methods.numberToFixed(this.discountsData.percent)
      this.discountsData.reducePrice = this.$methods.numberToFixed(this.discountsData.reducePrice)
      this.discountsData.mainStyle.forEach(item => {
        if (this.discountsData.selectDiscountType === 'reducePercent') {
          item.currentDiscount = this.$methods.numberToFixed(Math.max(item.price - (item.price * this.discountsData.percent / 100)), 0)
          console.log(`toFix: ${this.$methods.numberToFixed(item.price - (item.price * this.discountsData.percent / 100))}`)
          console.log(`currentDiscount: ${item.currentDiscount}`)
        } else if (this.discountsData.selectDiscountType === 'bothGetHigher') {
          const percent = this.discountsData.percent / 100
          const reducePercent = this.$methods.numberToFixed(Math.max(item.price - (item.price * percent), 0))
          const reducePrice = this.$methods.numberToFixed(Math.max(item.price - this.discountsData.reducePrice, 0))
          reducePercent >= reducePrice ? item.currentDiscount = reducePercent : item.currentDiscount = reducePrice
        } else if (this.discountsData.selectDiscountType === 'bothGetLower') {
          const percent = this.discountsData.percent / 100
          const reducePercent = this.$methods.numberToFixed(Math.max(item.price - (item.price * percent), 0))
          const reducePrice = this.$methods.numberToFixed(Math.max(item.price - this.discountsData.reducePrice, 0))
          reducePercent >= reducePrice ? item.currentDiscount = reducePrice : item.currentDiscount = reducePercent
        }
        if (this.discountsData.selectFinal === 'mathRound') item.currentDiscount = Math.round(item.currentDiscount)
      })
    },
    // 監聽優惠 > 減少金額
    listenReducePrice() {
      this.discountsData.percent = this.$methods.numberToFixed(this.discountsData.percent)
      this.discountsData.reducePrice = this.$methods.numberToFixed(this.discountsData.reducePrice)
      this.discountsData.mainStyle.forEach(item => {
        if (this.discountsData.selectDiscountType === 'reducePrice') {
          item.currentDiscount = this.$methods.numberToFixed(Math.max(item.price - this.discountsData.reducePrice, 0))
        } else if (this.discountsData.selectDiscountType === 'bothGetHigher') {
          const percent = this.discountsData.percent / 100
          const reducePercent = this.$methods.numberToFixed(Math.max(item.price - (item.price * percent), 0))
          const reducePrice = this.$methods.numberToFixed(Math.max(item.price - this.discountsData.reducePrice, 0))
          reducePercent >= reducePrice ? item.currentDiscount = reducePercent : item.currentDiscount = reducePrice
        } else if (this.discountsData.selectDiscountType === 'bothGetLower') {
          const percent = this.discountsData.percent / 100
          const reducePercent = this.$methods.numberToFixed(Math.max(item.price - (item.price * percent), 0))
          const reducePrice = this.$methods.numberToFixed(Math.max(item.price - this.discountsData.reducePrice, 0))
          reducePercent >= reducePrice ? item.currentDiscount = reducePrice : item.currentDiscount = reducePercent
        }
        if (this.discountsData.selectFinal === 'mathRound') item.currentDiscount = Math.round(item.currentDiscount)
      })
    },
    // 新增優惠
    createDiscount(status) {
      const vm = this
      const createDiscountApi = `${process.env.VUE_APP_API}/merchandiseDiscount/create`
      const header = {
        authorization: this.serverToken
      }
      const minQuantity = this.discountsData.minQuantity ? this.discountsData.minQuantity : 0
      let maxQuantity = this.discountsData.maxQuantity ? this.discountsData.maxQuantity : 0
      if (!minQuantity) return this.SweetAlert('other', '最小購買數量必須大於0')
      if (minQuantity >= maxQuantity) maxQuantity = null
      this.$methods.switchLoading('show')
      let data = []
      this.discountsData.mainStyle.forEach(item => {
        data.push({
          merchandiseStyleId: item.id,
          minQuantity: minQuantity,
          maxQuantity: maxQuantity,
          price: item.currentDiscount,
          storeVipId: this.discountsData.storeVipId ? this.discountsData.storeVipId : null
        })
      })
      $.ajax({
        type: 'POST',
        async: true,
        url: createDiscountApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            if (status === 'batchAdd') {
              vm.discountsData = {
                storeVipId: '',
                minQuantity: 1,
                maxQuantity: 0,
                selectDiscountType: 'setOwnPrice',
                setOwnPrice: 0,
                percent: 0,
                reducePrice: 0,
                selectFinal: 'mathRound',
                mainStyle: vm.selectMainStyles,
              }
            } else {
              vm.addDiscountsModal.hide()
              vm.getMerchandiseStyles()
              vm.selectMainStyles = []
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除優惠
    delDiscount(discountInfo) {
      const vm = this
      const delDiscountApi = `${process.env.VUE_APP_API}/merchandiseDiscount/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('merchandiseDiscountIds', discountInfo.id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delDiscountApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getMerchandiseStyles()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 款式排序
    saveSequence(hierarchy) {
      this.$methods.switchLoading('show')
      const vm = this
      const saveSequenceApi = `${process.env.VUE_APP_API}/merchandiseStyle/reorderSequence`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      let styleIds = []
      switch (hierarchy) {
        case 0:
          this.merchandiseStyles.mainStyles.forEach(item => {
            styleIds.push(item.id)
          })
          break;
        case 1:
          this.merchandiseStyles.oneStyles.forEach(item => {
            styleIds.push(item.id)
          })
          break;
        case 2:
          this.merchandiseStyles.twoStyles.forEach(item => {
            styleIds.push(item.id)
          })
          break;
        case 3:
          this.merchandiseStyles.threeStyles.forEach(item => {
            styleIds.push(item.id)
          })
          break;
        case 4:
          this.merchandiseStyles.fourStyles.forEach(item => {
            styleIds.push(item.id)
          })
          break;
      }
      data.append('styleIds', styleIds.toString())
      $.ajax({
        type: 'PUT',
        async: true,
        url: saveSequenceApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getMerchandiseStyles()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 批次新增細項 > 檢查目前批次新增系統預設款式的狀態(切換 tab)
    setBatchStyleStatus(status) {
      this.batchStyleCurrentStatus = status
      this.D4SystemStyles = {
        selectColors: [],
        selectSizes: [],
      }
      this.batchCreateByNumberResult = []
      this.batchCreateByNumber = {
        startNumber: 1,
        endNumber: 10,
        betweenNumber: 1,
        insertFirstText: "",
        insertEndText: "",
        paddingDigits: 0,
      }
      this.commonStyle = ''
    },
    // 批次新增細項
    batchCreateSystemD4Styles() {
      const vm = this
      const batchCreateSystemD4StylesApi = `${process.env.VUE_APP_API}/merchandiseStyle/create`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      this.mainStyle.name = ''
      switch (this.batchStyleCurrentStatus) {
        case 'color':
          if (this.D4SystemStyles.selectColors.length === 0) return this.SweetAlert('other', '請至少選擇一筆顏色')
          this.D4SystemStyles.selectColors.sort((a, b) => {
            return a.index - b.index
          })
          this.D4SystemStyles.selectColors.forEach((item, index, arr) => {
            data.push({
              merchandiseId: this.merchandiseId,
              hierarchy: this.currentEditStyleStatus,
              name: item.value,
            })
            // 如果是主要款式的話，不直接新增到資料庫，而是新增到輸入框內
            if (this.currentEditStyleStatus === 0) {
              this.mainStyle.name += item.value
              if (index !== arr.length - 1) this.mainStyle.name += '，'
            }
          })
          break;
        case 'size':
          if (this.D4SystemStyles.selectSizes.length === 0) return this.SweetAlert('other', '請至少選擇一筆衣服尺寸')
          this.D4SystemStyles.selectSizes.sort((a, b) => {
            return a.index - b.index
          })
          this.D4SystemStyles.selectSizes.forEach((item, index, arr) => {
            data.push({
              merchandiseId: this.merchandiseId,
              hierarchy: this.currentEditStyleStatus,
              name: item.value,
            })
            // 如果是主要款式的話，不直接新增到資料庫，而是新增到輸入框內
            if (this.currentEditStyleStatus === 0) {
              this.mainStyle.name += item.value
              if (index !== arr.length - 1) this.mainStyle.name += '，'
            }
          })
          break;
        case 'number':
          const error = this.batchCreateByNumberResult.some(item => {
            return item.length > 20
          })
          if (error) return this.SweetAlert('other', '您所組的款式字數超過20字')
          this.batchCreateByNumberResult.forEach((item, index, arr) => {
            data.push({
              merchandiseId: this.merchandiseId,
              hierarchy: this.currentEditStyleStatus,
              name: item,
            })
            // 如果是主要款式的話，不直接新增到資料庫，而是新增到輸入框內
            if (this.currentEditStyleStatus === 0) {
              this.mainStyle.name += item
              if (index !== arr.length - 1) this.mainStyle.name += '，'
            }
          })
          break;
        case 'commonStyles':
          if (!this.commonStyle) return this.SweetAlert('other', '您尚未選取任何常用款式')
          const array = this.commonStyle.replace(/\s*/g, "").split("，")
          array.forEach((item, index, arr) => {
            data.push({
              merchandiseId: this.merchandiseId,
              hierarchy: this.currentEditStyleStatus,
              name: item,
            })
            // 如果是主要款式的話，不直接新增到資料庫，而是新增到輸入框內
            if (this.currentEditStyleStatus === 0) {
              this.mainStyle.name += item
              if (index !== arr.length - 1) this.mainStyle.name += '，'
            }
          })
          break;
      }
      if (this.currentEditStyleStatus === 0) {
        this.batchAddSystemD4StylesModal.hide()
        return
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: batchCreateSystemD4StylesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getMerchandiseStyles()
            vm.batchAddSystemD4StylesModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 檢查上下架與收單時間的關係
    checkReady() {
      this.checkReadyError = false
      if (this.editMerchandiseData.ready && this.editMerchandiseData.checkDeadLineTime && this.editMerchandiseData.deadLineTime) {
        const now = new Date() * 1
        const deadLineTime = new Date(this.editMerchandiseData.deadLineTime) * 1
        if (deadLineTime <= now) this.checkReadyError = true
      }
    },
    // 加逗號
    addComma(where, recordString) {
      try {
        const el = document.querySelector(`#${where}`)
        const startCaret  = el.selectionStart // 起始游標位置
        const endCaret = el.selectionEnd // 結束游標位置
        // 檢查是否有字串
        if (recordString === 'mainStyle' && !this.mainStyle.name) return
        else if (!this[recordString]) return
        // 若有字串就加逗號
        if (recordString === 'mainStyle') this.mainStyle.name = this.mainStyle.name.substring(0, endCaret) + '，' + this.mainStyle.name.substring(endCaret)
        else this[recordString] = this[recordString].substring(0, endCaret) + '，' + this[recordString].substring(endCaret)
      } catch (error) {
        console.log(error)
        alert('您所在的瀏覽器或您的裝置不支援此功能')
      }
    },
    // 編輯模式
    editMode(id) {
      $(`#${id}`).addClass('edit-mode')
      $('.edit-mode-bg').removeClass('d-none')
      this.editModeStatus[id] = true
      switch (id) {
        case 'SupplierTable':
          this.recordSupplierLinks = []
          this.recordSupplierLinks = JSON.parse(JSON.stringify(this.supplierLinks.data))
          break;
        case 'MainStylesTable':
          this.recordMainStyles = []
          this.recordMainStyles = JSON.parse(JSON.stringify(this.merchandiseStyles.mainStyles))
          break;
        case 'OneStylesTable':
          this.recordOneStyles = []
          this.recordOneStyles = JSON.parse(JSON.stringify(this.merchandiseStyles.oneStyles))
          break;
        case 'TwoStylesTable':
          this.recordTwoStyles = []
          this.recordTwoStyles = JSON.parse(JSON.stringify(this.merchandiseStyles.twoStyles))
          break;
        case 'ThreeStylesTable':
          this.recordThreeStyles = []
          this.recordThreeStyles = JSON.parse(JSON.stringify(this.merchandiseStyles.threeStyles))
          break;
        case 'FourStylesTable':
          this.recordFourStyles = []
          this.recordFourStyles = JSON.parse(JSON.stringify(this.merchandiseStyles.fourStyles))
          break;
        default:
          alert('editMode 未處理的錯誤')
          break;
      }
    },
    // 關閉編輯模式
    closeEditMode(id, recoverData) {
      if (recoverData) {
        // 需要復原資料
        switch (id) {
          case 'SupplierTable':
            this.supplierLinks.data = JSON.parse(JSON.stringify(this.recordSupplierLinks))
            break;
          case 'MainStylesTable':
            this.merchandiseStyles.mainStyles = JSON.parse(JSON.stringify(this.recordMainStyles))
            break;
          case 'OneStylesTable':
            this.merchandiseStyles.oneStyles = JSON.parse(JSON.stringify(this.recordOneStyles))
            break;
          case 'TwoStylesTable':
            this.merchandiseStyles.twoStyles = JSON.parse(JSON.stringify(this.recordTwoStyles))
            break;
          case 'ThreeStylesTable':
            this.merchandiseStyles.threeStyles = JSON.parse(JSON.stringify(this.recordThreeStyles))
            break;
          case 'FourStylesTable':
            this.merchandiseStyles.fourStyles = JSON.parse(JSON.stringify(this.recordFourStyles))
            break;
          default:
            alert('closeEditMode 未處理的錯誤')
            break;
        }
      }
      $(`#${id}`).removeClass('edit-mode')
      $('.edit-mode-bg').addClass('d-none')
      this.editModeStatus[id] = false
    },
    // 過濾商品分類 (商品分類設定，隱藏的、不在開放時間內的都不給選)
    merchClassificationsFilter(merchClassifications) {
      const now = new Date() * 1
      const merchClassificationsFilterResult = merchClassifications.map((obj,key) => ({
            ...obj,
            show: obj.hidden === false && (obj.openInterval === false || (obj.openInterval === true &&  obj.enableTime <= now && now <= obj.expirationTime)) ? true:false
      }))
      return merchClassificationsFilterResult
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-mode-responsive {
  overflow-x: scroll;
}
.edit-mode {
  position: relative;
  & .edit-area {
    position: relative;
    z-index: 1001;
  }
}
.edit-mode-bg {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>